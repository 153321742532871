import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, InputNumber, DatePicker, Tabs, Checkbox } from "antd";
import moment from "moment";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { agencyService, HelperService } from "../../../services";
import { SaveBtn, CancelBtn } from "../../../common/components/form";

import "./HubForm.scss";

const { TabPane } = Tabs;

class HubForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: 0,
      Allocations: 0,
      HandsetAllocations: 0,
      Force: {Id: 1, Name: ""},
      StartDate: moment(),
      EndDate: moment(),
      HandsetStartDate: moment(),
      HandsetEndDate: moment(),
      IsRollingContract: false,
      IsHandsetRollingContract: false,
    };
  }

  pivotData = (data) => ({
    Id: this.props.Id,
    Allocations: data.Allocations,
    HandsetAllocations: data.HandsetAllocations,
    HandsetStartDate: data.HandsetStartDate,
    HandsetEndDate: data.HandsetEndDate,
    LawEnforcementAgencyId: data.Force.Id,
    StartDate: data.StartDate,
    EndDate: data.EndDate,
    IsRollingContract: data.IsRollingContract,
    IsHandsetRollingContract: data.IsHandsetRollingContract,
  });

  render() {
    const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];

    const ValidationSchema = Yup.object().shape({
      Id: Yup.number(),
      Allocations: Yup.string()
        .typeError("Allocation is required")
        .required("Allocation is required"),
      HandsetAllocations: Yup.string()
        .typeError("Handset Allocation is required")
        .required("Handset Allocation is required"),
      StartDate: Yup.string()
        .typeError("Start Date is required")
        .required("Start Date is required"),
      EndDate: Yup.string()
        .typeError("End Date is required")
        .required("End Date is required"),
      HandsetStartDate: Yup.string()
        .typeError("Start Date is required")
        .required("Start Date is required"),
      HandsetEndDate: Yup.string()
        .typeError("End Date is required")
        .required("End Date is required"),
    });

    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          Allocations: this.props.Allocations || this.state.Allocations,
          HandsetAllocations:
            this.props.HandsetAllocations || this.state.HandsetAllocations,
          HandsetStartDate:
            moment(this.props.HandsetStartDate) || this.state.HandsetStartDate,
          HandsetEndDate:
            moment(this.props.HandsetEndDate) || this.state.HandsetEndDate,
          Force: this.props.isUpdateMode === false ? {} : this.props.Force || this.state.Force,
          StartDate: moment(this.props.StartDate) || this.state.StartDate,
          EndDate: moment(this.props.EndDate) || this.state.EndDate,
          IsRollingContract:
            this.props.IsRollingContract || this.state.IsRollingContract,
          IsHandsetRollingContract:
            this.props.IsHandsetRollingContract ||
            this.state.IsHandsetRollingContract,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Form
            autoComplete={0}
            name="HubForm"
            id="HubForm"
            onChange={this.handleChange}
            onSubmit={handleSubmit}
          >
            <div className="p5-form-container">
              <Row justify="center">
                <Col span={22}>
                  <h1 className="form-label">{this.props.title}</h1>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                <Col xs={24} sm={24} md={20} lg={14} className="gutter-row">
                  <Form.Item
                    className="forcePickerBox"
                    validateStatus={
                      errors && errors.Force && touched.Force ? "error" : null
                    }
                    help={errors && touched.Force && errors.Force}
                  >
                    <div className="p5-form-label">
                      <span>Force</span>
                    </div>
                    <DynamicSelect
                      isDisabled={this.props.isUpdateMode}
                      hasNone={false}
                      className="p5-form-dropdown"
                      key="ForcePicker"
                      getData={this.props.isUpdateMode ? agencyService.getForceList : HelperService.getUnallocatedForceList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={values.Force.Id}
                      onChange={(val) => {
                        setFieldValue("Force.Id", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                <Col className="gutter-row" xs={24} sm={24} md={18} lg={14}>
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="TecSAFE App" key="1">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                        <Col className="gutter-row pl-3" xs={24} sm={24} md={12}>
                          <Form.Item
                            className="allocation"
                            validateStatus={
                              errors &&
                              errors.Allocations &&
                              touched.Allocations
                                ? "error"
                                : null
                            }
                            help={
                              errors &&
                              touched.Allocations &&
                              errors.Allocations
                            }
                          >
                            <div className="p5-form-label">
                              <span>Allocation</span>
                            </div>
                            <InputNumber
                              className="p5-form-number-input"
                              style={{ width: "100%" }}
                              id="Allocations"
                              min={0}
                              max={100000}
                              value={values.Allocations}
                              onChange={(val) => {
                                setFieldValue("Allocations", val);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row pl-3" xs={24} sm={24} md={12}>
                          <div className="mt-4">
                            <Form.Item>
                              <Checkbox
                                checked={values.IsRollingContract}
                                value={values.IsRollingContract}
                                onChange={(val) => {
                                  setFieldValue(
                                    "IsRollingContract",
                                    val.target.checked
                                  );
                                  setFieldValue("EndDate", values.StartDate);
                                }}
                              >
                                Is Rolling Contract?
                              </Checkbox>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                        <Col xs={24} sm={24} md={12} className="gutter-row pl-3">
                          <Form.Item
                            className="datePicker"
                            validateStatus={
                              errors && errors.StartDate && touched.StartDate
                                ? "error"
                                : null
                            }
                            help={
                              errors && touched.StartDate && errors.StartDate
                            }
                          >
                            <div className="p5-form-label">
                              <span>Start Date</span>
                            </div>
                            <DatePicker
                              className="p5-form-datepicker-input"
                              value={moment(values.StartDate)}
                              format={dateFormat[0]}
                              onChange={(val) => {
                                setFieldValue("StartDate", val || this.props.StartDate);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} className="gutter-row pl-3">
                          <Form.Item
                            className="datePicker"
                            validateStatus={
                              errors && errors.EndDate && touched.EndDate
                                ? "error"
                                : null
                            }
                            help={errors && touched.EndDate && errors.EndDate}
                          >
                            <div className="p5-form-label">
                              <span>End Date</span>
                            </div>
                            <DatePicker
                              className="p5-form-datepicker-input"
                              value={moment(values.EndDate)}
                              format={dateFormat[0]}
                              disabled={values.IsRollingContract}
                              onChange={(val) => {
                                setFieldValue("EndDate", val || this.props.EndDate);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Handset" key="2">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                        <Col xs={24} sm={24} md={12} className="gutter-row pl-3">
                          <Form.Item
                            className="allocation"
                            validateStatus={
                              errors &&
                              errors.HandsetAllocations &&
                              touched.HandsetAllocations
                                ? "error"
                                : null
                            }
                            help={
                              errors &&
                              touched.HandsetAllocations &&
                              errors.HandsetAllocations
                            }
                          >
                            <div className="p5-form-label">
                              <span>Allocation</span>
                            </div>
                            <InputNumber
                              className="p5-form-number-input"
                              style={{ width: "100%" }}
                              id="HandsetAllocations"
                              min={0}
                              max={100000}
                              value={values.HandsetAllocations}
                              onChange={(val) => {
                                setFieldValue("HandsetAllocations", val);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} className="gutter-row pl-3">
                          <div className="mt-4">
                            <Form.Item>
                              <Checkbox
                                checked={values.IsHandsetRollingContract}
                                value={values.IsHandsetRollingContract}
                                onChange={(val) => {
                                  setFieldValue(
                                    "IsHandsetRollingContract",
                                    val.target.checked
                                  );
                                  setFieldValue(
                                    "HandsetEndDate",
                                    values.HandsetStartDate
                                  );
                                }}
                              >
                                Is Rolling Contract?
                              </Checkbox>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} className="gutter-row pl-3">
                          <Form.Item
                            className="datePicker"
                            validateStatus={
                              errors &&
                              errors.HandsetStartDate &&
                              touched.HandsetStartDate
                                ? "error"
                                : null
                            }
                            help={
                              errors &&
                              touched.HandsetStartDate &&
                              errors.HandsetStartDate
                            }
                          >
                            <div className="p5-form-label">
                              <span>Start Date</span>
                            </div>
                            <DatePicker
                              className="p5-form-datepicker-input"
                              value={moment(values.HandsetStartDate)}
                              format={dateFormat[0]}
                              onChange={(val) => {
                                setFieldValue("HandsetStartDate", val || this.props.HandsetStartDate);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} className="gutter-row pl-3">
                          <Form.Item
                            className="datePicker"
                            validateStatus={
                              errors &&
                              errors.HandsetEndDate &&
                              touched.HandsetEndDate
                                ? "error"
                                : null
                            }
                            help={
                              errors &&
                              touched.HandsetEndDate &&
                              errors.HandsetEndDate
                            }
                          >
                            <div className="p5-form-label">
                              <span>End Date</span>
                            </div>
                            <DatePicker
                              className="p5-form-datepicker-input"
                              value={moment(values.HandsetEndDate)}
                              format={dateFormat[0]}
                              disabled={values.IsHandsetRollingContract}
                              onChange={(val) => {
                                setFieldValue("HandsetEndDate", val || this.props.HandsetEndDate);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
              <br />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  className="gutter-row"
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  className="gutter-row"
                >
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

HubForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  Id: PropTypes.number,
  Allocations: PropTypes.number,
  HandsetAllocations: PropTypes.number,
  Force: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  }),
  StartDate: PropTypes.string,
  EndDate: PropTypes.string,
  HandsetStartDate: PropTypes.string,
  HandsetEndDate: PropTypes.string,
  title: PropTypes.string,
  IsRollingContract: PropTypes.bool,
  IsHandsetRollingContract: PropTypes.bool,
  loading: PropTypes.bool,
  isUpdateMode: PropTypes.bool,
};

HubForm.defaultProps = {
  // onSubmit: () => {},
  onCancel: () => {},
  Id: 0,
  Allocations: 0,
  HandsetAllocations: 0,
  Force: {
    Id: 1,
    Name: "",
  },
  StartDate: moment().toString(),
  EndDate: moment().toString(),
  HandsetStartDate: moment().toString(),
  HandsetEndDate: moment().toString(),
  title: "",
  IsRollingContract: false,
  IsHandsetRollingContract: false,
  loading: false,
  isUpdateMode: false,
};

export default HubForm;
