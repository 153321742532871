
const liteTourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const LiteNewRegistrationSteps = [    
  {
    title: "Add New Registration",
    id: "new-reg-icon",
    text: [
      `
      <p>
      To start a new registration, click the '+' icon near the top right side of the Registered page. 
      </p>
      <p>
      This will open a new window where you can commence registering the TecSAFE App or TecSAFE handset being issued to the victim.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-add-icon ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",
      },
    ],
    when: {
        complete: () => {},
        hide: () => { 
          document.getElementById("sh-show-reg-btn").click();       
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {
          document.getElementById("sh-hide-reg-btn").click();
        },
    },
  }, 
  {
    title: "Registration Type Select",
    id: "new-reg-type-select",
    text: [
      `
      <p>
      This dropdown menu shows the type of devices that can be registered - TecSAFE App or TecSAFE handset. 
      </p>
      <p>
      Select a registration type.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-type-select ", on: "left" },
    beforeShowPromise: () => {
      document.getElementById("sh-show-reg-btn").click();
    },
    buttons: [
        {
          classes: "shepherd-button-secondary",
          text: "Back",
          type: "back",
        },
        {
          classes: "shepherd-button-primary",
          text: "Next",  
          type: "next",
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Confirmation Code",
    id: "new-reg-confirm-code",
    text: [
      `
      <p>
      This field only applies to registering the TecSAFE App.  If a TecSAFE handset is being registered, the Confirmation Code box disappears as it is not needed.  
      </p>
      <p>
      The confirmation code is the 6-digit numeric or alpha-numeric code found in the 'About' page of the TecSAFE App.   
      </p>
      <p>
      When registering a TecSAFE App, enter and select the users confirmation code.  
      This can be done by typing the first few characters of the confirmation code and then selecting the relevant code when it appears. 
      </p>
      
      `,
    ],
    attachTo: { element: ".sh-tour-confirm-code ", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Country Code Select",
    id: "new-reg-country-code",
    text: [
      `
      <p>
      Enter and select the victim's country code. This is the code for the country the victim's mobile phone or the TecSAFE handset is registered in. 
      </p>
      <p>
      The UK country code has been defaulted to the top of the list as this is most likely to be the most frequently used country code. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-country-code-select ", on: "right" },
    buttons: [
        {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Phone Number",
    id: "new-reg-phone-number-cli",
    text: [
      `
      <p>
      Enter and select the victim's mobile  number, or the TecSAFE mobile number - but do not include the initial '0'. 
      </p>
      <p>
      For a UK mobile number this will be a ten-digit number starting with the '7'.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-phone-number-cli ", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next",
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        next: () => {},
    },
  }, 
  {
    title: "Add New Registration",
    id: "new-reg-button",
    beforeShowPromise: () => {
      document.getElementById("sh-add-new-reg-step").click();
    },
    text: [
      `
      <p>
      Click 'Add New Registration' button to add the registration
      </p>
      <p>
      This will open up a new window requiring you to complete the minimum victim information for the registration.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-add-new-reg-btn ", on: "right" },
    buttons: [
        {
          classes: "shepherd-button-secondary",
          text: "Back",
          type: "back",
        },
        {
          classes: "shepherd-button-primary",
          text: "Next",       
          type: "next"
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Adding Minimum Victim Information",
    id: "new-min-user-data",
    beforeShowPromise: () => {
      document.getElementById("sh-add-new-reg-min-step").click();
    },
    text: [
      `
      <p>
      This is a window requiring a minimum level of victim information to enable the registration.
      Any field with a red asterisk is mandatory and must be completed.    
      </p>
      <p>
      `
    ],
    attachTo: { element: ".sh-tour-min-user-data ", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",   
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Gender",
    id: "new-min-user-gender",
    text: [
      `
      <p>
      This contains a dropdown menu enabling selection of the victim's preferred choice of gender identification. 
      </p>
      <p>
      From the dropdown list, select the relevant gender option for the victim being registered. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-gender ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Local Reference Number",
    id: "new-min-user-ref-num",
    text: [
      `
      <p>
      This is the local reference number for the case being registered.  
      Type in the reference number.
      </p>
      <p>
      If the local reference number is not known, type 'None' in the text box.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-refnum ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Risk Level",
    id: "new-min-user-risk-level",
    text: [
      `
      <p>
      This is a dropdown menu containing three different risk levels:
      </p>
      <p>
      <ul>
      <li>High</li>
      <li>Medium</li>
      <li>Standard</li>
      </ul>
      </p>
      <p>
      From the dropdown list, select the relevant risk level for the Victim being registered.   
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-risk-level ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Risk Categories",
    id: "new-min-user-ris-cat",
    text: [
      `
      <p>
      There are a number of broad risk categories that can be selected in relation to an individual registration.  
      From the dropdown menu select any risk categories that may relate to the victim:
      </p>
      <p>
      <ul>
      <li>DA - General</li>
      <li>DA - Stalking/Harassment</li>
      <li>DA - Honour Based Abuse</li>
      <li>DA - Force Marriage</li>
      <li>CSE</li>
      <li>MDS (Modern Day Slavery)</li>
      <li>Serious Sexual Assault</li>
      </ul>
      </p>
      <p>
      As each individual risk category is selected it will automatically populate in the field.  Multiple categories may be selected.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-risk-cat ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Officer in the Case",
    id: "new-min-user-oic",
    text: [
      `
      <p>
      Type in the name of officer or staff member dealing with the case.  
      </p>
      <p>
      Usually the officer in the case is the person completing the registration.  
      </p>
      <p>
      This can be amended later if the officer in the case changes.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-oic ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Officer Staff ID",
    id: "new-min-user-oic-staffid",
    text: [
      `
      <p>
      Add the staff ID number  for the officer in the case. 
      </p>
      <p>
      This is a free text box and alpha-numeric characters are allowed. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-oic-staffid ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Authorising Officer",
    id: "new-min-user-auth-oic",
    text: [
      `
      <p>
      Some forces require an authorising officer for the issue of TecSAFE devices (not mandatory).  
      </p>
      <p>
      Where this is the case, type in the name of the authorising officer in the text field. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-auth-oic ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Save Minimum Data",
    id: "new-min-sav=btn",
    beforeShowPromise: () => {
      document.getElementById("sh-add-new-reg-min-step").click();
    },
    text: [
      `
      <p>
      Click 'Save' to save the registered minimum victim data stating the 'Registration is Complete'.    
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-min-save", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next",   
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "New Registration Complete",
    id: "new-reg-complete",
    beforeShowPromise: () => {
      document.getElementById("sh-add-new-reg-complete-step").click();
    },
    text: [
      `
      <p>
      That concludes the Registration Grid. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        // action: () => this.complete(),
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    liteTourOptions,
    LiteNewRegistrationSteps
  }