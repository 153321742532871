import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  Tooltip,
  Tag,
  Input,
  Button,
  Space,
  Modal
} from "antd";
import {
  AndroidFilled,
  AppleFilled,
  MobileOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faUserClock, faClock } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import { TapActivateForm } from "../form";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import {
  AreaService,
  TeamService,
} from "../../../services";
import { phoneNumberFormatter } from "../../../common/utils/phone-number-helper";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import registrationActions from "../actions";
import { BatteryIcons } from '../../../common/components/icons-buttons';

const {
  BatteryFull,
  BatteryThreeQuarter,
  BatteryHalf,
  BatteryQuarter,
  BatteryEmtpy,
} = BatteryIcons;

class AdminRegisteredGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
      showTrackAPhone: false,
      record: {},
    };
  }

  GetDeviceType = (deviceTypeId) => {
    if (deviceTypeId === "Apple") {
      return (
        <span>
          <Tooltip title="Apple">
            <AppleFilled />
          </Tooltip>
        </span>
      );
    }
    if (deviceTypeId === "Android") {
      return (
        <span>
          <Tooltip title="Android">
            <AndroidFilled />
          </Tooltip>
        </span>
      );
    }
    return (
      <span>
        <Tooltip title="Handset">
          <MobileOutlined />
        </Tooltip>
      </span>
    );
  };

  GetLastLocTimeStatus = (record) => {
    if(record.RegistrationEndDate) {
      const activeUtcTime = moment(record.LastUpdate).local();
      const currentTimeDelta = moment().local().subtract(24, 'hours');
      const lessThan24hourAgo = currentTimeDelta.isAfter(activeUtcTime);

      if(record.LastUpdate && lessThan24hourAgo) {
        return (
          <span className="sh-tour-reg-no-loc-24hr">
            <FontAwesomeIcon
              style={{color: '#B90505', marginRight: '3px' }}
              icon={faExclamationCircle}
            />
          </span>
        );
      }
    }
    return '';
    
  };

  GetStatusIcon = (record) => {
    let faIcon;

    if(record.RegistrationEndDate) {
      const endDate = new Date(record.RegistrationEndDate);      
      const daysTil = (endDate - (new Date())) / (1000 * 60 * 60 * 24);
      const activeUtcTime = moment(record.LastUpdate).local();
      const currentTimeDelta = moment().local().subtract(1, 'days');
      const lessThan24hourAgo = currentTimeDelta.isAfter(activeUtcTime);

      if(daysTil <= 0) {        
        faIcon = {
          color: '#B90505',
          icon: faExclamationCircle,
          tooltip: 'Review Due',
          class: "sh-tour-expired-exclamation"
        };
    } else if(daysTil < 7) {
        faIcon = {
          color: '#B57500',
          icon: faClock,
          tooltip: 'Review Due Soon',
          class: "sh-tour-review-soon"
        };
    } else if(record.LastUpdate && lessThan24hourAgo) {
      faIcon = {
        color: '#003975',
        icon: faUserClock,
        tooltip: 'No recent location check In',
        class: "sh-tour-no-loc-checkin"
      };
    } else {
      faIcon = {
        color: 'black',
        icon: null,
        class: ""
      };
    }
    }
    return (
      <>
        {
          faIcon !== null ? (
            <div className={`d-flex ${faIcon.class}`}>
              {faIcon.icon !== null && (
                <Tooltip title={faIcon.tooltip}>
                  <FontAwesomeIcon
                    style={{color: faIcon !== null && faIcon !== undefined && faIcon.color !== undefined ? faIcon.color : '', marginTop: '6px' }}
                    icon={(faIcon !== null && faIcon !== undefined) && faIcon.icon !== undefined ? faIcon.icon : ''}
                  />
                </Tooltip>
              )}              
              <span style={{color:"207603", marginLeft: '5px', fontSize: '16px' }}>
                {
                  (faIcon !== null && faIcon !== undefined && faIcon.color !== undefined) && faIcon.color === '#B90505' ? (
                    <b>
                      {record.VictimName}
                    </b>
                  ) : <p>{record.VictimName}</p>
                }
              </span>
            </div>
          ) : ''
        }
      </>
    );
  };

  GetStatusDetails = (id, Text, record) => {
    const statusColors = { 1: "#B90505", 2: "#D38900", 3: "#207603" };

    const statusIcons = {
      1: <CloseCircleOutlined />,
      2: <WarningOutlined />,
      3: <CheckCircleFilled />,
    };

    if(id === 3) {
      return (
        <span style={{ marginRight: "40px" }}>
          <Tag
            style={{ width: "60px", marginTop: '3px', marginRight: '2px', border: "none", backgroundColor: "white", color: "#207603" }}
            color={statusColors[id] || "#ff0000"}
            icon={statusIcons[id] || <CloseCircleOutlined />}
          >
            <b>{Text}</b>
          </Tag>
        </span>
      );
    }
    return (
      <span style={{ marginRight: "40px" }}>
        <Tag
          onClick={(Text === 'T@P' || Text === 'BT') && id === 2 ? () => this.openTrackAPhone(record) : null}
          style={{ cursor: (Text === 'T@P' || Text === 'BT') && id === 2 ? 'pointer' : null, width: "60px", marginTop: '3px', marginRight: '2px' }}
          color={statusColors[id] || "#ff0000"}
          icon={statusIcons[id] || <CloseCircleOutlined />}
        >
          {Text}
        </Tag>
      </span>
    );
  };

  handleEditData = (record) => {
    if (record.VictimId === null && hasRights([rights.CanManageRegistration]) && record.TeamId === this.props.TeamId)
      this.props.dispatch(push(`/register/${record.RegistrationId}/create`));
    else if(hasRights([rights.CanManageRegistration]) && record.TeamId === this.props.TeamId)
      this.props.dispatch(push(`/register/${record.RegistrationId}/edit`));
  };

  GetVictimStatus = (id, Text, record) => {
    const statusColors = { 0: "#B90505", 6: "#D38900", 7: "#207603" };

    const statusIcons = {
      0: <CloseCircleOutlined />,
      6: <WarningOutlined />,
      7: <CheckCircleFilled />,
    };

    if(id === 7) {
      return (
        <span style={{ marginRight: "40px" }}>
          <Tag
            onClick={() => this.handleEditData(record)}
            style={{ cursor: (hasRights([rights.CanManageRegistration]) && record.TeamId === this.props.TeamId) ? 'pointer' : null,
              width: "60px", marginTop: '3px', marginRight: '2px', border: "none", backgroundColor: "white", color: "#207603" }}
            color={statusColors[id] || "#ff0000"}
            icon={statusIcons[id] || <CloseCircleOutlined />}
          >
            <b>{Text}</b>
          </Tag>
        </span>
      );
    }

    return (
      <span style={{ marginRight: "40px" }}>
        <Tag
          onClick={() => this.handleEditData(record)}
          style={{ cursor: (hasRights([rights.CanManageRegistration]) && record.TeamId === this.props.TeamId) ? 'pointer' : null,
            width: "60px", marginTop: '3px', marginRight: '2px' }}
          color={statusColors[id] || "#ff0000"}
          icon={statusIcons[id] || <CloseCircleOutlined />}
        >
          {Text}
        </Tag>
      </span>
    );  
  };

  getRowClassName = (record) => {
    let thisClass = "";
    
    if(record.RegistrationEndDate){
      const endDate = new Date(record.RegistrationEndDate);      
      const daysTil = (endDate - (new Date())) / (1000 * 60 * 60 * 24);
      if(record.Active){
        thisClass = "registration-valid sh-tour-active-grid-state";
      }else if(daysTil <= 0){        
        thisClass = "registration-invalid sh-tour-invalid-grid-state";
      }else if(daysTil < 7){
        thisClass = "registration-warning sh-tour-warning-grid-state";
      }
      else {
        thisClass = "sh-tour-normal-grid-state";
      }
    }
    return thisClass;
  }

  closeTrackAPhone = () => {
    this.setState({ showTrackAPhone: false });

    const { dispatch } = this.props;
    dispatch(registrationActions.hideRegistrationEditModal(this.props.onGridRefresh()));
    this.props.dispatch(
      registrationActions.GetRegistrationList(
        "$orderby=RegistrationId asc&$filter=IsRegistered eq true"
      )
    );
  };

  openTrackAPhone = async(record) => {
    await this.setState({ record });
    this.setState({ showTrackAPhone: true });
  };

  ActivateTAP = (data) => {
    this.props.dispatch(
      registrationActions.TrackaphoneActivate(data, this.closeTrackAPhone)
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  getPhoneColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? phoneNumberFormatter(text.toString()) : ""}
        />
      ) : (
        phoneNumberFormatter(text)
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getBatteryIcon = (level) => {
    if (level < 5) return BatteryEmtpy;
    if (level <= 25) return BatteryQuarter;
    if (level <= 50) return BatteryHalf;
    if (level <= 75) return BatteryThreeQuarter;

    return BatteryFull;
  };

  render() {
    const registeredUsersColumns = [
      {
        title: "Registered User",
        dataIndex: "VictimName",
        key: "VictimName",
        width: "10%",
        className: "sh-tour-victim-name",
        sorter: (a, b) => a.VictimName.length - b.VictimName.length,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('VictimName'),
        render: (text, record) => (
          <>
            {
              this.GetStatusIcon(record)
            }
          </>
      )},
      {
        title: "Force",
        dataIndex: "LawEnforcementAgencyName",
        key: "LawEnforcementAgencyName",
        width: "10%",
        className: "sh-tour-reg-force-name",
        sorter: (a, b) => a.LawEnforcementAgencyName.length - b.LawEnforcementAgencyName.length,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('LawEnforcementAgencyName'),
      },
      {
        title: "Area",
        dataIndex: "AreaName",
        key: "AreaName",
        width: "10%",
        className: "sh-tour-reg-area-name",
        sorter: (a, b) => (a.AreaName === null ? "" : a.AreaName.length) - (b.AreaName === null ? "" : b.AreaName.length),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('AreaName'),
        render: (text, record) =>
          record.AreaName !== null ? (
            <>
              <span>
                {text}
              </span>
            </>
          ) : null,
      },
      {
        title: "Team",
        dataIndex: "TeamName",
        key: "TeamName",
        width: "10%",
        className: "sh-tour-reg-team-name",
        sorter: (a, b) => (a.TeamName === null ? "" : a.TeamName.length) - (b.TeamName === null ? "" : b.TeamName.length),
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('TeamName'),
        render: (text, record) =>
          record.TeamName !== null ? (
            <>
              <span>
                {text}
              </span>
            </>
          ) : null,
      },
      {
        title: "Type",
        dataIndex: "RegistrationType",
        key: "RegistrationType",
        width: "5%",
        className: "sh-tour-reg-type",
        sorter: (a, b) => a.RegistrationType.length - b.RegistrationType.length,
        sortDirections: ["descend", "ascend"],
        filters: [
          {
            text: "TecSAFE App",
            value: "TecSAFE App",
          },
          {
            text: "Handset",
            value: "Handset",
          },
        ],
        onFilter: (value, record) => record.RegistrationType === value,
      },
      {
        title: "Device",
        dataIndex: "DeviceName",
        key: "DeviceName",
        width: "5%",
        className: "sh-tour-device",
        render: (value) => this.GetDeviceType(value),
        filters: [
          {
            text: "Apple",
            value: "Apple",
          },
          {
            text: "Android",
            value: "Android",
          },
          {
            text: "Handset",
            value: "Handset",
          },
        ],
        onFilter: (value, record) => record.DeviceName === value,
      },
      {
        title: "Phone Number",
        dataIndex: "PhoneNumber",
        key: "PhoneNumber",
        width: "10%",
        className: "sh-tour-phone-number",
        sorter: (a, b) => a.PhoneNumber - b.PhoneNumber,
        sortDirections: ["descend", "ascend"],
        render: (value, record) =>
          (record.PhoneCountryCode
            ? `+${phoneNumberFormatter(record.PhoneCountryCode)} `
            : "") + (phoneNumberFormatter(value) || ""),
        ...this.getPhoneColumnSearchProps('PhoneNumber'),
      },
      {
        title: "Last Loc Update",
        dataIndex: "LastUpdate",
        key: "LastUpdate",
        width: "10%",
        className: "sh-tour-last-loc",
        sorter: (a, b) => new Date(a.LastUpdate) - new Date(b.LastUpdate),
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          this.props.registrationList.length >= 1 ? (
            <>
              {this.GetLastLocTimeStatus(record)}
              <span>
                {record.LastUpdate != null
                  ? moment(record.LastUpdate).local().format("Do MMM YYYY, h:mm a")
                  : ""}
              </span>
            </>
          ) : null,
      },
      {
        title: "",
        dataIndex: "BatteryLevel",
        key: "BatteryLevel",
        width: "5%",
        className: "sh-tour-battery-level",
        render: (text, record) =>
          record.BatteryLevel !== null ? (
            <>
              <span
                style={{
                  color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: record.Active ? "normal" : "italic",
                }}
              >
                <Tooltip title={`${record.BatteryLevel}%`}>
                  {this.getBatteryIcon(record.BatteryLevel)}
                </Tooltip>
              </span>
            </>
          ) : null,
      },
      {
        title: "Review Due",
        dataIndex: "RegistrationEndDate",
        key: "RegistrationEndDate",
        width: "10%",
        className: "sh-tour-end-date",
        sorter: (a, b) =>  moment(a.RegistrationEndDate) - moment(b.RegistrationEndDate),
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          this.props.registrationList.length >= 1 ? (
            <>
              <span>
                {record.RegistrationEndDate != null
                  ? moment(record.RegistrationEndDate).local().format(
                      "Do MMM YYYY, h:mm a"
                    )
                  : ""}
              </span>
            </>
          ) : null,
      },
      {
        title: "OIC",
        dataIndex: "Oicname",
        key: "Oicname",
        width: "5%",
        className: "sh-tour-reg-oic",
        sorter: (a, b) => a.Oicname.length - b.Oicname.length,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('Oicname'),
        render: (text,record) => 
          record.Oicname !== null ?(
            <>
              {record.Oicname}
            </>
          ) : '',
      },
      {
        title: "Status",
        key: "Status",
        width: "5%",
        className: "sh-tour-reg-status",        
        render: (text, record) =>
          this.props.registrationList.length >= 1 ? (
            <>
              <Row gutter={[32, 8]}>
                <Col span={12} className="sh-tour-victim-data-status">
                  {this.GetVictimStatus(
                    record.VictimDataStatus !== null ? record.VictimDataStatus : 0,
                    "Data",
                    record
                  )}
                </Col>
                <Col span={12} className="sh-tour-victim-cli-status">
                  {this.GetStatusDetails(
                    record.PhoneNumber !== null ? 3 : 1,
                    "CLI",
                    record
                  )}
                </Col>
                <Col span={12} className="sh-tour-tap-status">
                  {record.TrackaphoneRegistrationStatusId &&
                    this.GetStatusDetails(
                      record.TrackaphoneRegistrationStatusId,
                      "T@P",
                      record
                    )}
                </Col>
                <Col span={12} className="sh-tour-bt-status">
                  {record.BtregistrationStatusId &&
                    this.GetStatusDetails(record.BtregistrationStatusId, "BT", record)}
                </Col>                
              </Row>
            </>
          ) : null,
      },
      {
        title: "",
        id: "RegistrationActions",
        dataIndex: "RegistrationId",
        key: "RegistrationId",
        width: "5%",
        render: (text, record) =>
          this.props.registrationList.length >= 1 &&
          (hasRights([rights.CanManageRegistration]) && record.TeamId === this.props.TeamId) || this.props.IsTour ? (
            <>
              <div className="sh-tour-red-detail-ellipsis">
                <Tooltip title="Actions">
                  <FontAwesomeIcon
                    className="ml-2"
                    onClick={() => this.props.IsTour ? console.log('This action is disabled during tour') : this.props.openModal(record.RegistrationId, record.ConsumerId)}
                    size="1x"
                    icon={faEllipsisH}
                  />
                </Tooltip>
              </div>
            </>
          ) : null,
      },
    ];

    return (
      <>
        <div className="p5-grid-page-container">
          {this.props.IsTour && (
            <>
              <Button id="sh-show-reg-detail-btn" style={{display: "None"}} onClick={() => this.props.openModal(-1, -1)} />
            </>
          )}
          <Row justify="center">
            <Col span={24}><h5>Filters</h5></Col>
            <Col span={24} style={{marginBottom: "15px"}}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                { hasRights([rights.CanManageAreaUsers]) && (
                  <Col xs={24} sm={24} md={12} lg={6} className="gutter-row">
                    <div><strong>Area</strong></div>
                    <DynamicSelect
                      key="AreaPicker"
                      className="p5-form-dropdown sh-tour-reg-area-filter"
                      hasNone={hasRights([rights.CanManageAreaUsers])}
                      getData={AreaService.getAreaList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={this.props.areaFilter}
                      filters={{ force: this.props.ForceId }}
                      onChange={(val) => {this.props.filterArea(val)}}
                    />
                  </Col>    
                )}
                {hasRights([rights.CanManageAreaUsers]) && hasRights([rights.CanManageTeamUsers]) ? (
                  <Col xs={24} sm={24} md={12} lg={6} className="gutter-row">
                    <div><strong>Team</strong></div>
                    <DynamicSelect
                      key="TeamPicker"
                      className="p5-form-dropdown sh-tour-reg-team-filter"
                      hasNone={hasRights([rights.CanManageTeamUsers])}
                      getData={TeamService.getTeamList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={this.props.teamFilter}
                      filters={{
                          force: this.props.ForceId,
                          area:
                          this.props.areaFilter === 0
                              ? null
                              : this.props.areaFilter,
                        }}
                      onChange={(val) => {this.props.filterTeam(val)}}
                    />
                  </Col>
                ) : (
                  <Col xs={24} sm={24} md={12} lg={6} className="gutter-row">
                    <div><strong>Team</strong></div>
                    <DynamicSelect
                      key="TeamPicker"
                      className="p5-form-dropdown sh-tour-reg-team-filter"
                      hasNone={hasRights([rights.CanManageTeamUsers])}
                      getData={TeamService.getTeamList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={this.props.teamFilter}
                      filters={{
                          force: this.props.ForceId,
                          area: this.props.AreaId,
                        }}
                      onChange={(val) => {this.props.filterTeam(val)}}
                    />
                  </Col>                    
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Table
                id="RegistrationTable"
                loading={this.props.loading}
                dataSource={this.props.registrationList}
                columns={registeredUsersColumns}
                rowClassName={this.getRowClassName}
                scroll={{ x: 700 }}
                size="large"
                rowKey='RegistrationId'
              />
            </Col>
          </Row>
        </div>
        <Modal
          visible={this.state.showTrackAPhone}
          onCancel={() => this.closeTrackAPhone()}
          footer={null}
          width={700}
          destroyOnClose
        >
          <TapActivateForm
            onCancel={() => this.closeTrackAPhone()}
            onSubmit={this.ActivateTAP}
            isLoading={this.props.TapActivationLoading}
            {...this.state.record}
          />
        </Modal>
      </>
    );
  }
}

AdminRegisteredGrid.propTypes = {
  dispatch: PropTypes.func,
  openModal: PropTypes.func,
  filterArea: PropTypes.func,
  filterTeam: PropTypes.func,
  onGridRefresh: PropTypes.func,
  registrationList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  ForceId: PropTypes.number,
  AreaId: PropTypes.number,
  TeamId: PropTypes.number,
  teamFilter: PropTypes.number,
  areaFilter: PropTypes.number,
  TapActivationLoading: PropTypes.bool,
  IsTour: PropTypes.bool,
};

AdminRegisteredGrid.defaultProps = {
  dispatch: () => {},
  openModal: () => {},
  filterArea: () => {},
  filterTeam: () => {},
  onGridRefresh: () => {},
  registrationList: [{}],
  loading: false,
  ForceId: null,
  AreaId: null,
  TeamId: null,
  teamFilter: null,
  areaFilter: null,
  TapActivationLoading: false,
  IsTour: false,
};

function mapStateToProps(state) {
    const { loading, teamFilter, areaFilter, TapActivationLoading } = state.RegisterUserFormState;
    const { ForceId, AreaId, TeamId } = state.AccountState.currentUser;
    return {
      loading,
      ForceId,
      AreaId,
      TeamId,
      teamFilter,
      areaFilter,
      TapActivationLoading,
    };  
  }

const connectedAdminRegisteredGrid = connect(mapStateToProps)(AdminRegisteredGrid);

export { connectedAdminRegisteredGrid as default };
