
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const DashboardSteps = [
  {
    title: "General Description",
    id: "tour-dash-general-desc",
    text: [
      `
      <p>
      This page is only available to Force Admins and provides overview information about the use and deployment of TecSAFE devices for their force.  
      </p>
      `,
    ],
    attachTo: { on: "center" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
  {
    title: "Dashboard LEA Filter",
    id: "tour-dash-lea-filter",
    text: [
      `
      <p>
      The top bar contains filters used to separate out the data viewed in the dashboard.  
      </p>
      <p>
      Where separate Areas and teams have been created, Force admins can filter the Area and Team data and focus on specific Areas or Teams.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-lea-filter", on: "bottom" },
    buttons: [
      
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
  {
    title: "Dashboard Realtime Data",
    id: "tour-dash-realtime-sect",
    text: [
      `
      <p>
      This section shows data in real time for your entire Force.  This data is not updated or changed by any of the filters. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-realtime-sect", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      next: () => {},
      show: () => {
        const element = document.getElementById("realtime-sect");
        element.scrollIntoView({
          behavior: "smooth",
          block: "top",
          inline: "top",
        });
      },
    },
  },
  {
    title: "Dashboard Reviews Overdue",
    id: "tour-dash-overdue",
    text: [
      `
      <p>
      The Bar Graph shows the total number and percentage of existing Registrations that are overdue review.  
      </p>
      <p>
      There are two bar graphs. 
      The Red one indicates TecSAFE Apps and the Blue one indicates TecSAFE Handsets.
      </p>
      <p>
      To find individual overdue registrations, go to the 'Registered' page and overdue registrations will be showing with a 'red' background across the row.   
      Registrations can be extended from the Registered page. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-overdue", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("overdue-review");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard No Location Reported",
    id: "tour-dash-noloc-report",
    text: [
      `
      <p>
      The Bar Graph shows the total and percentage of Registrations that have not reported a location in over 24 hours.  
      There are two bar graphs: the red one indicates WN Apps and the blue one indicates TecSAFE Handsets.  
      </p>
      <p>
      To find individual registrations that have not reported locations,  go to the 'Registration' page and you will see a red '!'  alongside the most recent time and date reported information.
      </p>
      <p>
      The 'Last Loc Update' column will indicate the most recent time that registered device reported a location to TecSAFE.  
      </p>
      <p>
      You may need to follow up with individual victims to make sure their phone is switched on and/or that the TecSAFE App is open.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-noloc-report", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("noloc-report");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard TecSAFE App Allocation",
    id: "tour-dash-app-alloc-pie",
    text: [
      `
      <p>
      This pie chart shows the deployment of TecSAFE App licences across your Force.   
      </p>
      <p>
      Where Areas have been created, each segment of the pie chart shows the number and percentage of TecSAFE App licences that Area has deployed, along with a segment showing and how many licences remain unused. 
      </p>
      <p>
      Where no Areas have been created the pie chart shows totals for the Force. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-app-alloc-pie", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("app-allocation");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Handset Allocation",
    id: "tour-dash-handset-alloc-pie",
    text: [
      `
      <p>
      This pie chart shows the deployment of TecSAFE handsets across your Force.   
      </p>
      <p>
      Where Areas have been created, each segment of the pie chart shows the number and percentage of TecSAFE handsets that Area has deployed, along with a segment showing and how many TecSAFE handsets remain unused.  
      </p>
      <p>
      Where no Areas have been created the pie chart shows totals for the Force. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-handset-alloc-pie", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("handset-allocation");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Filtered Data",
    id: "tour-dash-filtered-sect",
    text: [
      `
      <p>
      This section of the Dashboard contains information for the entire Force.  It can be filtered by Areas and Teams where they have been created, as well as by date.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-filtered-sect", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("filtered-sect");
        element.scrollIntoView({
          behavior: "smooth",
          block: "top",
          inline: "top",
        });
      },
    },
  },
  {
    title: "Dashboard Date Filters",
    id: "tour-dash-data-date-filter",
    text: [
      `
      <p>
      There are three date filters that allow you to see information based on specific time periods.      
      </p>
      <p>
      The 'Start' and 'End' date fields filter the information within the 'Filtered Data' section for the time periods specified. 
      </p>
      <p>
      There is also a checkbox, labelled 'View Entire History'.
      </p>
      <p>
      If this checkbox is ticked it will ignore the start and end dates and show all  data since your Force went live with the TecSAFE portal. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-data-date-filter", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("data-date-filter");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard New Devices",
    id: "tour-dash-new-devices",
    text: [
      `
      <p>
      This section of the dashboard indicates the number of new devices that have been registered by your Force within the given date filters.      
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-new-devices", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("new-devices");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Activated Devices",
    id: "tour-dash-activated-devices",
    text: [
      `
      <p>
      This section of the dashboard indicates the number of your Force's devices that have been activated within the given date filters.     
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-activated-devices", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("activated-devices");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Registered TecSAFE Apps",
    id: "tour-dash-app-registered-devices",
    text: [
      `
      <p>
      This section of the dashboard indicates the number of TecSAFE Apps that have been registered for your Force within the given date filters.    
      </p>
      <p>
      You will also see the total numbers separated out by Areas, if Areas have been created.  If no Areas have been created, the total number will be for the whole organisation.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-app-registered-devices", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("app-registered-devices");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Registered Handset Devices",
    id: "tour-dash-handset-registered-devices",
    text: [
      `
      <p>
      This section of the dashboard indicates the number of TecSAFE handsets that have been registered for your Force within the given date filters.       
      </p>
      <p>
      You will also see the total numbers separated out by Areas, if Areas have been created.  If no Areas have been created, the total number will be for the whole Force. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-handset-registered-devices", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("handset-registered-devices");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Risk Categories",
    id: "tour-dash-risk-cat-bar",
    text: [
      `
      <p>
      This section of the dashboard indicates the risk categories that have been identified for victims within the given date filters.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-risk-cat-bar", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("risk-cat-bar");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Gender",
    id: "tour-dash-gender-bar",
    text: [
      `
      <p>
      This section of the dashboard indicates the genders of victims within the given date filters.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-dash-gender-bar", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {
        const element = document.getElementById("gender-bar");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  },
  {
    title: "Dashboard Complete",
    id: "tour-dash-complete",
    text: [
      `
      <p>
      That concludes the Dashboard Tour. 
       Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        // action: () => this.complete(),
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    DashboardSteps
  }