
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const RegistrationSteps = [    
  {
    title: "Victim Name",
    id: "RegisteredUser",
    text: [
      `
      <p>
      This column shows the registered users (victim's) name.
      </p>
      <p>
      You can sort this column into ascending or descending order to make it easier to find an individual registration. 
      There is also a magnifying glass icon you can use to search for a victim name.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-victim-name ", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },  
  {
    title: "Registration Type",
    id: "RegisterationType",
    text: [
      `
      <p>
      This column is the registration type.
      When a new device is registered, you will either register a new TecSAFE App or a TecSAFE Handset device provided by police.  
      </p>
      <p>
      <ul>
          <li>TecSAFE App</li>
          <li>Handset</li>
      </ul>
      </p>
      You can sort this column into ascending or descending order to make it easier to find a registration under specific registration types. 
      There is also a filter option where you can select the type of registration to filter the registration grid. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-type", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Device Type",
    id: "RegDeviceType",
    text: [
      `
      <p>
      This column shows the type of device that has been registered for an individual victim.
      There are 3 types of devices that will show here:       
      </p>
      <p>
      <ul>
      <li>Apple (IOS)</li>
      <li>Android</li>
      <li>Handset</li>
      </ul>
      </p>
      <p>
      The TecSAFE Apps are available for both Apple (iOS) and Android. 
      You will also see another device type called a 'Handset'.
      This is a specially adapted TecSAFE mobile phone that is provided to the user by police. 
      </p>
      <p>
      There is a filter option where you can select the type of device to filter the registration grid.  
      </P>
      `,
    ],
    attachTo: { element: ".sh-tour-device", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Phone Number",
    id: "phone-number",
    text: [
      `
      <p>
      This column shows the registration phone number.
      For a TecSAFE App registration this will be the victim's own mobile number.
      For a TecSAFE handset this will be the handset mobile number.  
      </p>
      <p>
      You can sort this column into ascending or descending order to make it easier to find an individual registration. 
      There is also a magnifying glass icon you can use to search for a registration.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-phone-number", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Last Location Reported",
    id: "last-loc-report",
    text: [
      `
      <p>
      This column shows when the victim's device last reported a location to TecSAFE.
      </p>        
      <p>
      You can sort this column into ascending or descending order to make it easier to find an individual registration.
      </p>
      <p>        
      There are a several reasons why there may be no recent location update (i.e. within the last few hours), 
      including the victim's phone being switched off; the TecSAFE App no longer running on their phone or the TecSAFE handset having run out of battery.   
      </p>
      <p>
      You will also see a battery icon to the right of the last reported location.
      If you hover over that icon it will show the battery percentage remaining on the device at the time this most recent location was reported.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-last-loc", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Review Due",
    id: "reg-end-date",
    text: [
      `
      <p>
      This column shows the date a review is due for this registration.
      The first review will automatically be set for 8 weeks after the date of registration. 
      </p>
      <p>
      It is important to be aware of when a registration is due for review. 
      You can sort this column into ascending or descending order to make it easier to find a registration. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-end-date", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Office in the Case",
    id: "reg-oic",
    scrollTo: true,
    text: [
      `
      <p>
      This column shows the officer in the case for an individual registration.
      </p>
      <p>
      After you register a new device, you can amend the information about the officer dealing with this case. 
      </p>
      <p>
      You can sort this column into ascending or descending order to make it easier to find a registration. 
      There is also a magnifying glass icon you can use to search for a registration. 
      This can also help to sort and identify all registrations an individual officer is dealing with.   
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-oic", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Registration Status",
    id: "reg-status",
    scrollTo: true,
    text: [
      `
      <p>
      This column shows the 4 status icons for that registration. These icons are:
      </p>
      <p>
      <ul>
      <li>Data - Victim and Offender information</li>
      <li>CLI - Caller Line Identification - i.e. the mobile phone number of the registration</li>
      <li>T@P - the Trackaphone tracking platform that the TecSAFE portal links into (mainly for control room use)</li>
      <li>BT - the BT VULCAN database that the mobile number is linked with to enable BT 999 staff to announce the call as a 'Vulnerable Caller' to police call-takers. </li>
      </ul>
      </p>
      <p>
      There are 3 colours that each status could show as: 
      </p>
      <p>
      <ul>
      <li>Green - Completed</li>
      <li>Amber - Partially Completed</li>
      <li>Red - Not Completed</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-status", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },  
  {
    title: "Victim Data Status",
    id: "reg-data-status",
    text: [
      `
      <p>
      This status represents the extent that victim and offender data has been completed. Once a registration is completed and the required victim data has been added, this will show in green.
      </p>
      <p>
      There are 3 colours that this status could show as: 
      </p>
      <p>
      <ul>
      <li>Green - Completed</li>
      <li>Amber - Partially Completed</li>
      <li>Red - Not Completed</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-victim-data-status ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Victim CLI Status",
    id: "reg-cli-status",
    text: [
      `
      <p>
      This status represents the current state of integration of the registration with the Trackaphone tracking platform and the British Telecom VULCAN database.
      This is usually completed when you add the victim's phone number or a TecSAFE handset phone number.
      </p>
      <p>
      There are 3 colours that this status could show as:
      </p>
      <p>
      <ul>
      <li>Green - Completed</li>
      <li>Amber - Partially Completed</li>
      <li>Red - Not Completed</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-victim-cli-status ", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Trackaphone Status",
    id: "reg-tap-status",
    text: [
      `
      <p>
      This status represents the current state of integration of this registration with the Trackaphone tracking platform.
      </p>      
      <p>
      When you initiate the registration for a TecSAFE App this icon will initially be Amber (partially completed).  The victim's mobile phone will receive an SMS message containing a 6-digit code.
      Retrieve that 6-digit code from the victim's phone, click on the T@P icon and enter the 6-digit code into the box that appears.  This completes the Trackaphone integration.
      </p>
      <p>
      There is no SMS message when a TecSAFE handset is being registered and the Trackaphone Status will be immediately green. 
      </p>
      <p>
      There are 3 colours that this status could show as:  
      </p>
      <p>
      <ul>
      <li>Green - Completed</li>
      <li>Amber - Partially Completed</li>
      <li>Red - Not Completed</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-tap-status ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "BT Status",
    id: "reg-bt-status",
    text: [
      `
      <p>
      This status represents the current state of the integration of this registration with the British Telecom VULCAN database. 
      Once you complete the Trackaphone integration, the BT integration will also be completed. 
      </p>
      <p>
      There are 3 colours that this status could show as:  
      </p>
      <p>
      <ul>
      <li>Green - Completed</li>
      <li>Amber - Partially Completed</li>
      <li>Red - Not Completed</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-bt-status ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Normal",
    id: "reg-grid-normal",
    text: [
      `
    <p>
    A registration row that is showing with a white neutral background means the device is registered and has not yet reached the period when a review is due, and the device is not currently in an activated state.  
    </p>
    `,
    ],
    attachTo: { element: ".sh-tour-normal-grid-state ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      hide: () => {},
      destroy: () => {},
      show: () => {
        const element = document.getElementsByClassName("sh-tour-victim-name")[0];
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
      },
    },
  },
  {
    title: "No Recent Location Reported in 24 Hours",
    id: "reg-no-recent-loc-report",
    text: [
      `
      <p>
      If you see this 'portrait' icon, along with a red exclamation mark against the most recent time/date a location was reported, it means the registered device has not reported a location within the last 24 hours. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-no-loc-checkin ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Active",
    id: "reg-grid-warning",
    text: [
      `
      <p>
      If a registration row is showing with a blue background, it means the victim has activated their device (TecSAFE App or TecSAFE handset) within the last hour.
      The TecSAFE App or TecSAFE handset will be reporting its location every minute for a total of one hour after the activation commenced.    
      While the background of the registration is blue, that device cannot be deregistered.    
      </p>
      <p>
      After an hour, the background colour should automatically revert back to a white neutral colour.  That device is once again able to be deregistered when it does so.
      </p>
    `,
    ],
    attachTo: { element: ".sh-tour-active-grid-state ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Review Indicator",
    id: "reg-grid-warning",
    text: [
      `
      <p>
      If a registration row is showing with an Amber background, it means the registered device's 'review  date' will shortly be due.         
      </p>
      <p>
      The App or TecSAFE handset will continue working as expected, and the victim will be unaffected and unaware of the review prompt. 
      </p>
      <p>
      A review can be conducted once the background colour has turned Amber.  
      </p>
    `,
    ],
    attachTo: { element: ".sh-tour-warning-grid-state ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Review Due",
    id: "reg-grid-expired",
    text: [
      `
      <p>
      If a registration row is showing with a Red background, this means the registered device's 'review  date' is now due.        
      </p>
      <p>
      The App or TecSAFE handset will continue working as expected, and the victim will be unaffected and unaware of the review prompt. 
      </p>
  `,
    ],
    attachTo: { element: ".sh-tour-invalid-grid-state", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Registration Grid Complete",
    id: "reg-grid-complete",
    text: [
      `
      <p>
      That concludes the Registration Grid. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
  },
];

  export default {
    tourOptions,
    RegistrationSteps
  }