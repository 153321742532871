const AdminTourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: false,
    },
  },
  scrollTo: true,
  useModalOverlay: true,
};

const AdminRegistrationSteps = [
  {
    title: "Force Filters",
    id: "reg-org-filter",
    text: [
      `
        <p>
        Admin Users will see 2 dropdown options that will filter data on the registration grid.
        These filters are only available for Force and Area admin user.        
        </p>
        <p>
        Force admins can see multiple Areas and Teams in these dropdown options (where multiple Areas and/or Teams have been creation that Force).
        </p>
        <p>
        Area admins can only see multiple Teams with their own Area in these dropdown options (where multiple teams have been created).
        </p>
        `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",
        action: () => this.next(),
        type: "next",
      },
    ],
  },
  {
    title: "Area Filter",
    id: "reg-area-filter",
    text: [
      `
        <p>
        The Area Dropdown, shows the Areas you are assigned to.
        </p>
        <p>
        Force Admins will see all Areas listed under your Force.
        Changing the Area in this dropdown will automatically update the grid to only show registrations listed under the selected Area.  
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-reg-area-filter ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Team Filter",
    id: "reg-team-filter",
    text: [
      `
        <p>
        The Team Dropdown shows the teams that are listed under the currently selected area.       
        </p>
        <p>
        As a Force Admin, the list of teams you can select will be updated based on the Area you have selected.
        Some forces don't have Areas created.  In this case, the team is defaulted to the Force name.   
        Changing the team in this dropdown will automatically update the grid to only show registrations listed under the selected area/team combination.  
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-reg-team-filter ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Victim Name",
    id: "RegisteredUser",
    text: [
      `
        <p>
        This column shows the registered users (victim's) name.
        </p>
        <p>
        You can sort this column in ascending or descending order to make it easier to find a registration. 
        There is also a magnifying glass icon you can use to search for a victim name. 
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-victim-name ", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Force Name",
    id: "reg-force-name",
    text: [
      `
      <p>
      This column shows the Force the police user is linked to.
      </p>
      <p>
      You can sort this column into ascending or descending order to make it easier to find a registration. 
      You can also use the magnifying glass icon to search for an individual App ID.          
      </p>
          `,
    ],
    attachTo: { element: ".sh-tour-reg-force-name", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Area Name",
    id: "reg-area-name",
    text: [
      `
      <p>
      Where Areas have been created, this column shows the Area the police user is linked to.
      </p>
      <p>
      You can sort this column into ascending or descending order to make it easier to find a registration. 
      You can also use the magnifying glass icon to search for an individual App ID.         
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-area-name", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Team Name",
    id: "reg-team-name",
    text: [
      `
      <p>
      Where Teams have been created, this column shows the Team the police user is linked to.  If no Teams have been created the Team name will default to the Force name.  
      </p>
      <p>
      You can sort this column into ascending or descending order to make it easier to find a registration. 
      You can also use the magnifying glass icon to search for an individual App ID.            
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-reg-team-name", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Registration Type",
    id: "RegisterationType",
    text: [
      `
        <p>
        This column is the registration type.
        When a new device is registered, you will either register a new TecSAFE App or a TecSAFE Handset device provided by police.
        </p>
        <p>
        <ul>
            <li>TecSAFE App</li>
            <li>Handset</li>
        </ul>
        </p>
        You can sort this column into ascending or descending order to make it easier to find a registration under specific registration types. 
        There is also a filter option where you can select the type of registration to filter the registration grid. 
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-reg-type", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Device Type",
    id: "RegDeviceType",
    text: [
      `
        <p>
        This column shows the type of device that has registered for an individual victim.
        There are 3 types of devices that will show here:         
        </p>
        <p>
        <ul>
        <li>Apple (IOS)</li>
        <li>Android</li>
        <li>Handset</li>
        </ul>
        </p>
        <p>
        The TecSAFE Apps are available for both Apple (iOS) and Android. 
        You will also see another device type called a 'Handset'.
        This is a device that is provided to the user by police.  
        </p>
        <p>
        There is a filter option you use to select the type of device to filter the registration grid. 
        </P>
        `,
    ],
    attachTo: { element: ".sh-tour-device", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Phone Number",
    id: "phone-number",
    text: [
      `
        <p>
        This column shows the registered phone number.
        For a TecSAFE App registration this will be the victim's own mobile number.
        For a TecSAFE handset this will be the handset mobile number. 
        </p>
        <p>
        You can sort this column into ascending or descending order to make it easier to find an individual registration. 
        There is also a magnifying glass icon you can use to search for a registration.  
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-phone-number", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Last Location Reported",
    id: "last-loc-report",
    text: [
      `
        <p>
        This column shows when the victim's device last reported a location to TecSAFE.
        </p>        
        <p>
        You can sort this column into ascending or descending order to make it easier to find an individual registration.
        </p>
        <p>        
        There are a several reasons why there may be no recent location update (i.e. the last few hours), 
        including the victim's phone being switched off; the TecSAFE App no longer running on their phone or the TecSAFE handset having run out of battery. 
        </p>
        <p>
        You will also see a battery icon to the right of the last reported location.
        If you hover over that icon that will show the battery percentage left on the device at the time that last location was reported.  
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-last-loc", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Review Due",
    id: "reg-end-date",
    text: [
      `
        <p>
        This column shows the date a review is due for  that registration.
        The first review will be set for 8 weeks after the date of registration. 
        </p>
        <p>
        It is important to be aware of when a registration is due for review. 
        You can sort this column ascending or descending to make it easier to find a registration.  
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-end-date", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Office in the Case",
    id: "reg-oic",
    scrollTo: true,
    text: [
      `
        <p>
        This column shows the officer-in-the-case for an individual registration.
        </p>
        <p>
        After you register a new device, you can amend the information about the officer dealing with this case. 
        </p>
        <p>
        You can sort this column into ascending or descending order to make it easier to find a registration. 
        There is also a magnifying glass icon you can use to search for a registration. 
        This can also help to sort and identify all registrations an individual officer is dealing with.   
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-reg-oic", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Registration Status",
    id: "reg-status",
    scrollTo: true,
    text: [
      `
        <p>
        This column shows the 4 status icons for that registration. These icons are:
        </p>
        <p>
        <ul>
        <li>Data - Victim and Offender information</li>
        <li>CLI - Caller Line Identification - the mobile phone number of the registration</li>
        <li>T@P - the Trackaphone tracking platform that the TecSAFE portal links into (mainly for control room use)</li>
        <li>BT - the BT VULCAN database that the mobile number is linked with to enable BT 999 staff to announce the call as a 'Vulnerable Caller' to police call-takers.</li>
        </ul>
        </p>
        <p>
        There are 3 colours that each status could show up as:  
        </p>
        <p>
        <ul>
        <li>Green - Completed</li>
        <li>Amber - Partially Completed</li>
        <li>Red - Not Completed</li>
        </ul>
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-reg-status", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Victim Data Status",
    id: "reg-data-status",
    text: [
      `
        <p>
        This status represents the extent that victim and offender data has been completed. 
        Once a registration is completed and the required victim and offender data has been added, this will show in green.
        </p>
        <p>
        There are 3 colours this status could show as:
        </p>
        <p>
        <ul>
        <li>Green - Completed</li>
        <li>Amber - Partially Completed</li>
        <li>Red - Not Completed</li>
        </ul>
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-victim-data-status ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Victim CLI Status",
    id: "reg-cli-status",
    text: [
      `
        <p>
        This status represents the current state of integration of the registration with the Trackaphone tracking platform and the British Telecom VULCAN database.
        this is usually completed when you add the victim's phone number or a TecSAFE handset phone number.
        </p>
        <p>
        There are 3 colours that this status could show as: 
        </p>
        <p>
        <ul>
        <li>Green - Completed</li>
        <li>Amber - Partially Completed</li>
        <li>Red - Not Completed</li>
        </ul>
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-victim-cli-status ", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Trackaphone Status",
    id: "reg-tap-status",
    text: [
      `
        <p>
        This status represents the current state of integration of the registration with the Trackaphone tracking platform.
        When you initiate the registration for a TecSAFE App the victim's device will receive an SMS message containing a 6-digit code.
        You will need to retrieve that 6-digit  code from the victim, click on the T@P icon and enter it into the box that appears. 
        This completes the Trackaphone integration. 
        </p>
        <p>
        There are 3 colours that this status could show as: 
        </p>
        <p>
        <ul>
        <li>Green - Completed</li>
        <li>Amber - Partially Completed</li>
        <li>Red - Not Completed</li>
        </ul>
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-tap-status ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "BT Status",
    id: "reg-bt-status",
    text: [
      `
        <p>
        This status represents the current state of the integration of this registration with the British Telecom VULCAN database. 
        Once you complete the Trackaphone integration, the BT integration will also be completed. 
        </p>
        <p>
        There are 3 colours that this status could show as: 
        </p>
        <p>
        <ul>
        <li>Green - Completed</li>
        <li>Amber - Partially Completed</li>
        <li>Red - Not Completed</li>
        </ul>
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-bt-status ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Normal",
    id: "reg-grid-normal",
    text: [
      `
      <p>
      A registration row that is showing with a white neutral background means the device is registered and has not yet reached the point that a review is due, and the device is not currently in an activated state. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-normal-grid-state ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    when: {
      hide: () => {},
      destroy: () => {},
      show: () => {
        const element = document.getElementsByClassName("sh-tour-victim-name")[0];
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
      },
    },
  },
  {
    title: "No Recent Location Reported in 24 Hours",
    id: "reg-no-recent-loc-report",
    text: [
      `
        <p>
        If you see this 'portrait' icon, along with a red exclamation mark against the most recent time/date a location was reported, it means the registered device has not reported a location within the last 24 hours. 
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-no-loc-checkin ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Active",
    id: "reg-grid-warning",
    text: [
      `
        <p>
        If a registration row is showing with a blue background, it means the victim has activated their device (TecSAFE App or TecSAFE handset) within the last hour.
        The TecSAFE App or TecSAFE handset will be reporting its location every minute for a total of one hour after the activation commenced.    
        While the background of the registration is blue, that device cannot be deregistered.  
        </p>
        <p>
        After an hour, the background colour should automatically revert back to a white neutral colour.  That device is once again able to be deregistered when it does so.
        </p>
      `,
    ],
    attachTo: { element: ".sh-tour-active-grid-state ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Review indicator",
    id: "reg-grid-warning",
    text: [
      `
      <p>
      If a registration row is showing with an Amber background, it means the registered device's 'review  date' will shortly be due.       
      </p>
      <p>
      The App or TecSAFE handset will continue working as expected, and the victim will be unaffected and unaware of the review prompt. 
      </p>
      <p>
      A review can be conducted once the background colour has turned Amber.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-warning-grid-state ", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Grid Styling - Review Due",
    id: "reg-grid-expired",
    text: [
      `
      <p>
      If a registration row is showing with a Red background, this means the registered device's 'review  date' is now due.     
      </p>
      <p>
      The App or TecSAFE handset will continue working as expected, and the victim will be unaffected and unaware of the review prompt.
      </p>
    `,
    ],
    attachTo: { element: ".sh-tour-invalid-grid-state", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
  },
  {
    title: "Registration Grid Complete",
    id: "reg-grid-complete",
    text: [
      `
        <p>
        That concludes the Admin Registration Grid. 
        Please select Complete to this tour to mark as complete.
        </p>
        `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        // action: () => this.complete(),
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

export default {
  AdminTourOptions,
  AdminRegistrationSteps,
};
