import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Tooltip, Empty } from "antd";
import { CustomProgressBar } from "../../../common/components/dashboard";

class OverdueProgressView extends React.Component {
  formatpercentage = (value, decimalplaces) => {
    if (value % 1 > 0)
    {
      return value.toFixed(decimalplaces);
    }

    return value.toFixed(0);  
  }
  
  render() {
    return (
      <>
        <div className="p5-form-container">
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <h1 className="p5-dash-h1-header text-center">
                  <span>
                    <FontAwesomeIcon
                      style={{color: '#B90505', marginRight: "5px"}}
                      icon={faExclamationCircle}
                    />
                  </span>
                  <span>
                    {this.props.title}
                  </span>
                </h1>
              </div>
            </Col>
          </Row> 
          <br /> 
          {     
            this.props.overdueRegistrations.map((overdue) => (
              <Row key={`overdue-${overdue.RegistrationType}`} justify="space-around" align="middle">
                <Col span={8}><span>{overdue.RegistrationType}</span></Col>
                <Col span={16}>
                  <span style={{"float": "right"}}>
                    Total: {overdue.OverdueCount} / {overdue.TotalRegistration}
                  </span>
                </Col>
                <Col span={22}> 
                  <div style={{marginBottom: "-25px auto"}}>
                    <Tooltip placement="left" title={overdue.RegistrationType}>
                      <span>
                        <CustomProgressBar 
                          height={100} 
                          width={300} 
                          percent={overdue.OverdueCount / overdue.TotalRegistration} 
                          barWidthRatio={0.3} 
                          color={overdue.RegistrationType === 'TecSAFE App' ? ['#EF4E4E', '#E8EDF3'] : ['#243B53', '#E8EDF3']} 
                        />                            
                      </span>   
                    </Tooltip>   
                  </div>                    
                </Col>
                <Col span={2}>
                  <span style={{margin: "-25px 10px"}}>{this.formatpercentage((overdue.OverdueCount * 100 / overdue.TotalRegistration), 2)}%</span>                        
                </Col>
              </Row>  
            ))
        }        
          {
            this.props.overdueRegistrations.length === 0 && (
              <>
                <Empty />
              </>
              )
          }      
          <br />
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <span><strong>Data in this section does not change by date filter</strong></span>
              </div>
            </Col>
          </Row>
        </div>        
      </>
    );
  }
}

OverdueProgressView.propTypes = {
//   loading: PropTypes.bool,
  title: PropTypes.string,
  overdueRegistrations: PropTypes.arrayOf(PropTypes.shape({})),
};

OverdueProgressView.defaultProps = {
//   loading: false,
  title: "",
  overdueRegistrations: [],
};

export { OverdueProgressView as default };
