import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from "antd";
import { Column } from '@ant-design/charts';

class CustomBarChart extends React.Component {
  constructor(props) {
    super(props);
    const {xField, yField,seriesField} = this.props;
    this.state = {      
      chartConfig: {
          data: [],
          isGroup: true,
          xField: `${xField}`,
          yField: `${yField}`,
          seriesField: `${seriesField}`,
          // color: ['#EF4E4E', '#243B53'],
          color: (type) => {
            if(type[seriesField] === this.props.primaryColorType){
              return '#EF4E4E';
            }
            return '#243B53';
          },
          label: {
            position: 'middle',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' },
            ],
          },
        },
    }
  }

  componentDidMount() {
    this.setChartData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data || this.props.data > 0) {
      this.setChartData()
    }
  }

  setChartData = () =>{
    const {data, isGroup, xField, yField,seriesField} = this.props;
    this.setState({
      chartConfig: {
        data,
        isGroup,
        xField,
        yField,
        seriesField,
      }
    });
  }
    
render() {
  
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Column {...this.state.chartConfig} />
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

CustomBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  isGroup: PropTypes.bool,
  xField: PropTypes.string,
  yField: PropTypes.string,
  seriesField: PropTypes.string,
  primaryColorType: PropTypes.string,
};

CustomBarChart.defaultProps = {
  data: [],
  loading: false,
  isGroup: true,
  xField: '',
  yField: '',
  seriesField: 'DeviceType',
  primaryColorType: 'TecSAFE App',
};

export { CustomBarChart as default };