import moment from "moment";

const RegistrationData = [
  {
    RegistrationId: -1,
    RegistrationTypeId: 1,
    RegistrationType: "TecSAFE App",
    VictimId: 1,
    VictimDataStatus: 7,
    VictimName: "New Victim",
    RiskLevel: 3,
    Risk: "Standard",
    Oicname: "Johnson",
    OicstaffId: "0000",
    ConsumerId: -1,
    ConfirmationCode: "",
    DeviceName: "Handset",
    LawEnforcementAgencyId: 66,
    LawEnforcementAgencyName: "P5_test Test",
    AreaId: 1,
    AreaName: "Test Area",
    TeamId: 1,
    TeamName: "Test Team",
    LawEnforcementAgencySubGroupId: 21,
    PhoneCountryCode: "44",
    PhoneNumber: "5555555555",
    Longitude: 0.0,
    Latitude: 0.0,
    LastUpdate: moment().subtract(1, "hours").local(),
    BatteryLevel: 75,
    CreatedDate: "2023-03-30T00:00:00.000-05:00",
    RegistrationEndDate: moment().add(56, "days").local(),
    Active: false,
    TrackaphoneRegistrationStatusId: 3,
    BtregistrationStatusId: 3,
    IsRegistered: true,
    CanExtend: false,
    IsExpired: false,
    IsTour: true,
  },
  {
    RegistrationId: 1,
    RegistrationTypeId: 1,
    RegistrationType: "TecSAFE App",
    VictimId: 1,
    VictimDataStatus: 7,
    VictimName: "New Victim",
    RiskLevel: 3,
    Risk: "Standard",
    Oicname: "Johnson",
    OicstaffId: "0000",
    ConsumerId: 1,
    ConfirmationCode: "",
    DeviceName: "Handset",
    LawEnforcementAgencyId: 66,
    LawEnforcementAgencyName: "P5_test Test",
    AreaId: 1,
    AreaName: "Test Area",
    TeamId: 1,
    TeamName: "Test Team",
    LawEnforcementAgencySubGroupId: 21,
    PhoneCountryCode: "44",
    PhoneNumber: "5555555555",
    Longitude: 0.0,
    Latitude: 0.0,
    LastUpdate: moment().subtract(1, "days").local(),
    BatteryLevel: 75,
    CreatedDate: "2023-03-30T00:00:00.000-05:00",
    RegistrationEndDate: moment().add(56, "days").local(),
    Active: false,
    TrackaphoneRegistrationStatusId: 3,
    BtregistrationStatusId: 3,
    IsRegistered: true,
    CanExtend: false,
    IsExpired: false,
    IsTour: true,
  },
  {
    RegistrationId: 2,
    RegistrationTypeId: 1,
    RegistrationType: "TecSAFE App",
    VictimId: 1,
    VictimDataStatus: 7,
    VictimName: "New Victim",
    RiskLevel: 3,
    Risk: "Standard",
    Oicname: "Johnson",
    OicstaffId: "0000",
    ConsumerId: 2,
    ConfirmationCode: "",
    DeviceName: "Handset",
    LawEnforcementAgencyId: 66,
    LawEnforcementAgencyName: "P5_test Test",
    AreaId: 1,
    AreaName: "Test Area",
    TeamId: 1,
    TeamName: "Test Team",
    LawEnforcementAgencySubGroupId: 21,
    PhoneCountryCode: "44",
    PhoneNumber: "5555555555",
    Longitude: 0.0,
    Latitude: 0.0,
    LastUpdate: moment().subtract(1, "m").local(),
    BatteryLevel: 75,
    CreatedDate: "2023-03-30T00:00:00.000-05:00",
    RegistrationEndDate: moment().add(56, "days").local(),
    Active: true,
    TrackaphoneRegistrationStatusId: 3,
    BtregistrationStatusId: 3,
    IsRegistered: true,
    CanExtend: false,
    IsExpired: false,
    IsTour: true,
  },
  {
    RegistrationId: 3,
    RegistrationTypeId: 1,
    RegistrationType: "TecSAFE App",
    VictimId: 1,
    VictimDataStatus: 7,
    VictimName: "New Victim",
    RiskLevel: 3,
    Risk: "Standard",
    Oicname: "Johnson",
    OicstaffId: "0000",
    ConsumerId: 3,
    ConfirmationCode: "",
    DeviceName: "Handset",
    LawEnforcementAgencyId: 66,
    LawEnforcementAgencyName: "P5_test Test",
    AreaId: 1,
    AreaName: "Test Area",
    TeamId: 1,
    TeamName: "Test Team",
    LawEnforcementAgencySubGroupId: 21,
    PhoneCountryCode: "44",
    PhoneNumber: "5555555555",
    Longitude: 0.0,
    Latitude: 0.0,
    LastUpdate: moment().subtract(1, "h").local(),
    BatteryLevel: 75,
    CreatedDate: "2023-03-30T00:00:00.000-05:00",
    RegistrationEndDate: moment().add(7, "days").local(),
    Active: false,
    TrackaphoneRegistrationStatusId: 3,
    BtregistrationStatusId: 3,
    IsRegistered: true,
    CanExtend: false,
    IsExpired: false,
    IsTour: true,
  },
  {
    RegistrationId: 4,
    RegistrationTypeId: 2,
    RegistrationType: "Handset",
    VictimId: 6111,
    VictimDataStatus: 7,
    VictimName: "Test user",
    RiskLevel: 3,
    Risk: "Standard",
    Oicname: "john",
    OicstaffId: "0000",
    ConsumerId: 4,
    ConfirmationCode: "",
    DeviceName: "Handset",
    LawEnforcementAgencyId: 66,
    LawEnforcementAgencyName: "P5_test Test",
    AreaId: null,
    AreaName: null,
    TeamId: 1018,
    TeamName: "P5_test Test",
    LawEnforcementAgencySubGroupId: null,
    PhoneCountryCode: "44",
    PhoneNumber: "5555555559",
    Longitude: 0.0,
    Latitude: 0.0,
    LastUpdate: moment().subtract(1, "h").local(),
    BatteryLevel: 26,
    CreatedDate: "2022-10-07T19:35:37.673-04:00",
    RegistrationEndDate: moment().subtract(3, "days").local(),
    Active: false,
    TrackaphoneRegistrationStatusId: 3,
    BtregistrationStatusId: 3,
    IsRegistered: true,
    CanExtend: true,
    IsExpired: true,
    IsTour: true,
  },
];

export default {
  RegistrationData,
};
