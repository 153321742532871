
const tourFullOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const RegDetailTourSteps = [ 
    {
        title: "Registration Detail Modal",
        id: "tour-reg-detail-ellipsis",
        scrollTo: true,
        text: [
          `
          <p>
          This section describes the various actions that can be taken in relation to an individual registration.
          </p>
          <p>
          Click on the '‧‧‧' to the right of the four status icons for the registration.
          </p>
          <p>
          This opens the registration 'control box', allowing you to view a summary of the registration and giving access to various options for that registration. 
          </p>
          `,
        ],
        attachTo: { element: ".sh-tour-red-detail-ellipsis", on: "left" },        
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Next",   
            type: "next",
          },
        ],
        when: {
            complete: () => {},
            hide: () => {    
              document.getElementById("sh-show-reg-detail-btn").click();   
            },
            destroy: () => {},
            cancel: () => {},
            show: () => {
              document.getElementById("sh-hide-reg-detail-btn").click();              
            },
        },
      }, 
      {
        title: "Deregister Device",
        id: "tour-reg-detail-deregister",
        classes: "shepherd-elment-lg",
        text: [
          `
          <p>
          The only time you cannot deregister a device is when it is in an activated state.  
          </p>
          <p>
          In this case the background colour of the registration will be blue on the Registered page.  
          To deregister that registration you will need to wait until it has reset back into passive mode and the background colour of the registration is neutral white.
          </p>
          <p>
          It is imperative the victim is aware the deregistration is taking place beforehand as once deregistered, the emergency functionality on a TecSAFE App immediately ceases and will no longer work. 
          </p>
          <p>
          You will see a red 'Deregister' button located at the bottom left of the 'control box'.  To deregister a device, click the red 'Deregister' button. 
          </p>
          <p>
          You will be presented with a confirmation box asking if you are sure you wish to deregister [name of victim] and [mobile number].  
          To complete the deregistration click 'OK' and that registration will be removed from the Registered page.
          </p>
          `,
        ],
        attachTo: { element: ".sh-tour-red-detail-deregister", on: "right" },
        beforeShowPromise: () => {
          document.getElementById("sh-show-reg-detail-btn").click();
        },
        buttons: [
          {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",   
            type: "next", 
          },
        ],
        when: {
            complete: () => {},
            hide: () => {},
            destroy: () => {},
            cancel: () => {},
            show: () => {},
        },
      }, 
      {
        title: "View Data",
        id: "tour-reg-detail-data",
        text: [
          `
          <p>
          To view or amend all information relating to the victim and offender click the 'Data' button.  
          This will take you to the 'Victim Data' page for that registration.
          <p>
          `,
        ],
        attachTo: { element: ".sh-tour-red-detail-data", on: "top" },
        buttons: [
          {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",   
            type: "next", 
          },
        ],
        when: {
            complete: () => {},
            hide: () => {},
            destroy: () => {},
            cancel: () => {},
            show: () => {},
        },
      }, 
      {
        title: "Extend Registration",
        id: "tour-reg-detail-extend",
        classes: "shepherd-elment-xl",
        beforeShowPromise: () => {
          document.getElementById("sh-show-reg-detail-btn").click();
          const extendModal = document.getElementById("sh-hide-reg-extend-btn");
          if(extendModal)
            extendModal.click();
        },
        text: [
          `
          <p>
          You can review registration data at any time but you can only extend a registration when the background colour of that registration is either Amber (7 weeks after registration) or Red (8 weeks onwards after registration).
          </p>
          <p>
          When a registration is due for review you see a blue 'Extend' button within the 'control box'.  
          To carry out a review click on the blue 'Extend Button.  
          This will take you back into the Victim Data page to allow you to update and amend any of the information previously input, if necessary.  
          Once you have done so, go to the bottom of the form and click the 'Save and Extend' button.  
          This will return you to the 'control box'.  
          If you have completed the review within 8 weeks of the review due date , the blue 'Extend' button will disappear.   
          </p>
          <p>
          When you close the control box the background colour of the registration will be neutral white and the new review date will be set for 8 weeks from the date the original review was due. 
          </p>
          <p>
          If the review is conducted more than 8 weeks after the original review was due, the control box will still show the blue 'extend' button.  
          Click 'Extend' again and when you are in the 'Victim Data' form click 'Extend and Save'.  
          If the review is considerably overdue you may need to take this action a number of times until the blue 'Extend' button does not appear.
          </p>
          `,
        ],
        attachTo: { element: ".sh-tour-red-detail-extend", on: "left" },
        buttons: [
          {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",   
            type: "next", 
          },
        ],
        when: {
            complete: () => {},
            hide: () => {},
            destroy: () => {},
            cancel: () => {},
        },
      }, 
  {
    title: "Cancel/Save and Extend",
    id: "tour-vic-footer-btn",
    beforeShowPromise: () => {
      const extendModal = document.getElementById("sh-show-reg-extend-btn");
      if(extendModal)
        extendModal.click(); 
    },
    text: [
      `
      <p>
      Once you have completed updating any victim data information, click 'Save and Extend' to save any updated information and extend the registration, 
      or 'Cancel' if you do not want to extend the registration at this time. 
      </p>

      `,
    ],
    attachTo: { element: "#Extend-footer-btns", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next",   
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {
          // document.getElementById("sh-show-reg-detail-btn").click(); 
        },
        cancel: () => {},
        show: () => {
          const element = document.getElementById("Extend-footer-btns");
          setTimeout(() => element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}), 500);
        },
    },
  },
  {
    title: "Registration Detail Modal Complete",
    id: "tour-reg-det-complete",
    text: [
      `
      <p>
      That concludes the Registration Detail Modal. 
          Please select Complete to this tour to mark as complete.
      </p>

      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        // action: () => this.complete(),
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourFullOptions,
    RegDetailTourSteps
  }